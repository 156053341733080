<template>
	<div class="zong_inp" :class="{'zong_inp_rig':max_num === ''}">
		<input :type="text" class="from_input" v-model="inp_value" @input="shu_input" :placeholder="placeholder"
			:disabled="disabled">
		<div class="number" v-if="max_num !== ''">{{num}}/{{max_num}}</div>
	</div>

</template>
<script>
	export default {
		props: {
			text: {
				type: String,
				default: 'text'
			},
			name: {
				type: String,
				default: ''
			},
			max_num: {
				type: [String, Number],
				default: ''
			},
			placeholder: {
				type: String,
				default: ''
			},
			disabled: {
				type: Boolean,
				default: false
			},
			value: {
				type: [String, Number],
				default: '',
			},
			index: {
				type: [String, Number],
				default: 0,
			}
		},
		data() {
			return {
				num: 0,
				inp_value: '',
				inp_value1: '',
			}
		},
		mounted() {
			this.mvlaue();
		},
		methods: {

			mvlaue() {
				this.inp_value = '';
				if (this.value) {
					// console.log(this.value);
					this.inp_value = this.value;
					this.num = this.value.length;
				}
			},
			shu_input(e) {
				// console.log(e.target.value);                
				let value = e.target.value; // 获取到输入框的值
				let num = value.length // 输入框中的值的长度
				if (num > parseInt(this.max_num)) {
					this.inp_value = this.inp_value1
				} else {
					this.inp_value = value;
					this.inp_value1 = value;
					this.num = num;
					this.$emit('chenginput', {
						'value': value
					});
				}
			}
		}
	}
</script>
<style>
	.zong_inp {
		height: 40px;
		line-height: 40px;
		overflow: hidden;
		display: flex !important;
		align-items: center;
		padding-left: 10px;
		background-color: #FFF;
	}

	.zong_inp_rig {
		padding-right: 10px;
	}

	.from_input {
		flex: 1;
		height: 100%;
		margin: 0;
		border: none;
		outline: none;
		background-color: #FFF;
		background-image: none;
		box-sizing: border-box;
		color: #606266;
		display: inline-block;
		font-size: inherit;
		transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
		width: 100%;
	}

	.from_input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #c1c1c1;
		font-size: 14px;
	}

	.from_input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #c1c1c1;
		font-size: 14px;
	}

	.from_input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #c1c1c1;
		font-size: 14px;
	}

	.from_input::-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #c1c1c1;
		font-size: 14px;
	}

	.number {
		padding: 0 10px;
		color: #999;
		font-size: 16px;

	}
</style>
