<template>
  <div class="footer">
    <template v-if="count > 1">
      <div class="previous" :class="{ disable: on_page <= 1 }" @click="reduce">
        <div class="pagedisable"></div>
        上一页
      </div>
      <div class="page_list">
        <template v-if="count <= 6">
          <div
            v-for="item in count"
            :key="item"
            class="li_it"
            :class="{ li_it1: on_page == item }"
            @click="change_page(item)"
          >
            {{ item }}
          </div>
        </template>
        <template v-else>
          <div
            class="li_it"
            :class="{ li_it1: on_page == 1 }"
            @click="change_page(1)"
          >
            1
          </div>
          <div class="li_qit" v-if="on_page - ypage > 1">·····</div>
          <template v-for="item in count">
            <div
              :key="item"
              v-if="
                item != 1 &&
                item != count &&
                item > on_page - ypage &&
                item < on_page + ypage
              "
              class="li_it"
              :class="{ li_it1: on_page == item }"
              @click="change_page(item)"
            >
              {{ item }}
            </div>
          </template>
          <div class="li_qit" v-if="on_page + ypage < count">·····</div>
          <div
            class="li_it"
            :class="{ li_it1: on_page == count }"
            @click="change_page(count)"
          >
            {{ count }}
          </div>
        </template>
      </div>
      <div class="next" :class="{ disable: on_page >= count }" @click="add">
        <div class="pagedisable"></div>
        下一页
      </div>
    </template>
  </div>
</template>

<script>
export default {
  watch: {
    page(n) {
      this.che_ypage(n);
    },
  },
  props: {
    page: {
      type: [String, Number],
      default: 1,
    },
    count: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      on_page: 1,
      ypage: 3,
    };
  },
  methods: {
    che_ypage(page) {
      this.on_page = page;
      if (page <= 2) {
        this.ypage = 5;
      } else if (page >= this.count - 1) {
        this.ypage = 4;
      }
    },
    change_page(page) {
      if (page == this.on_page) {
        return;
      }
      this.on_page = page;
      this.yupage(page);
    },
    add() {
      if (this.on_page < this.count) {
        this.on_page++;
        this.yupage(this.on_page);
      }
    },
    reduce() {
      if (this.on_page > 1) {
        this.on_page--;
        this.yupage(this.on_page);
      }
    },
    yupage(page) {
      if (page <= 1 || page >= this.count) {
        this.ypage = 5;
      } else if (page == 2 || page >= this.count - 1) {
        this.ypage = 4;
      } else {
        this.ypage = 3;
      }
      this.change(page);
    },
    change(page) {
      this.$emit("change", {
        value: page,
      });
    },
  },
};
</script>

<style scoped>
@import "./index.css";
</style>
