<template>
  <div>
    <div class="create_head">活动信息</div>
    <div class="create_main">
      <div class="create_main1">
        <div class="m1_hd">编辑活动信息</div>
        <div class="m1_box">
          <div class="box_txt">活动名称：</div>
          <CusInput
            class="box_inp"
            :value="activity_name"
            v-if="is_edit"
            :max_num="20"
            @chenginput="chenginput"
          />
        </div>
        <div class="m1_box">
          <div class="box_txt">活动起止时间：</div>
          <el-date-picker
            v-model="act_start_end_time"
            @change="datetime"
            type="datetimerange"
            range-separator="——"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="date_picker"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="create_main2">
        <div class="m2_hd">设置活动类型和活动详情</div>
        <div class="m2_box">
          <div class="box_txt">活动类型：</div>
          <el-radio-group
            v-model="select_type"
            class="radio_group"
          >
            <el-radio :label="1">满立减</el-radio>
            <el-radio :label="2">满件折</el-radio>
            <el-radio :label="3">满包邮</el-radio>
          </el-radio-group>
        </div>
        <div class="m2_box">
          <div class="box_txt">活动使用范围：</div>
          <el-radio-group
            v-model="select_range"
            class="radio_group"
          >
            <el-radio :label="0">全店所有商品</el-radio>
            <el-radio :label="1">部分商品</el-radio>
          </el-radio-group>
        </div>
        <template v-if="select_range === 1">
          <div class="m2_box">
            <div class="box_txt"></div>
            <el-button class="add_goods" @click="show_add_tan(1)"
              >+添加商品<span v-if="select_list.length>0">(已选{{select_list.length}})</span></el-button
            >
          </div>
        </template>
        <template v-if="select_type === 1">
          <div class="m2_box">
            <div class="box_txt">订单金额大于等于：</div>
            <el-input class="m2_inp" v-model="total_price"></el-input>
            <span style="font-size: 17px; margin-left: 10px">元</span>
            <span style="font-size: 17px; margin-left: 10px">(0或者空代表无限制)</span>
          </div>
          <div class="m2_box">
            <div class="box_txt">立减：</div>
            <el-input class="m2_inp" v-model="leg_price"></el-input>
            <span style="font-size: 17px; margin-left: 10px">元</span>
          </div>
        </template>
        <template v-if="select_type === 2">
          <div class="m2_box">
            <div class="box_txt">订单件数满：</div>
            <el-input class="m2_inp" v-model="man_Lijian"></el-input>
            <span style="font-size: 17px; margin-left: 10px">件</span>
            <span style="font-size: 17px; margin-left: 10px">(0或者空代表无限制)</span>
          </div>
          <div class="m2_box">
            <div class="box_txt">立减折：</div>
            <el-input class="m2_inp" v-model="leg_piece"></el-input>
            <span style="font-size: 17px; margin-left: 10px">折</span>
          </div>
        </template>
        <template v-if="select_type === 3">
          <div class="m2_box1">
            <el-radio-group
              v-model="select_free_shipping"
              class="radio_group1"
            >
              <el-radio :label="0">
                <div class="yyo">
                  <div class="box_txt">单笔订单满：</div>
                  <el-input class="m2_inp" v-model="full_piece"></el-input>
                  <span style="font-size: 17px; margin-left: 10px">件</span>
                  <span style="font-size: 17px; margin-left: 10px">(0或者空代表无限制)</span>
                </div>
              </el-radio>
              <el-radio :label="1">
                <div class="yyo">
                  <div class="box_txt">单笔订单满：</div>
                  <el-input class="m2_inp" v-model="full_price"></el-input>
                  <span style="font-size: 17px; margin-left: 10px">元</span>
                </div>
              </el-radio>
            </el-radio-group>
          </div>
          <!-- <div class="m2_box">
            <div class="box_txt">单笔订单满：</div>
            <el-input class="m2_inp" v-model="full_piece"></el-input>
            <span style="font-size: 17px; margin-left: 10px">件</span>
          </div>
          <div class="m2_box">
            <div class="box_txt">单笔订单满：</div>
            <el-input class="m2_inp" v-model="full_price"></el-input>
            <span style="font-size: 17px; margin-left: 10px">元</span>
          </div> -->
        </template>
      </div>
    </div>
    <div class="create_footer">
      <el-button class="foot_bt1" @click="Previous">返回上一步</el-button>
      <el-button class="foot_bt2" @click="show_act_list">提交</el-button>
    </div>
    <!-- 添加商品参加活动对话框 -->
    <el-dialog
      class="bba"
      center
      :show-close="false"
      custom-class="dialog"
      :visible.sync="add_goods_tan"
    >
      <div class="dia_search">
        <div class="sea_icon iconfont icon-chaxun"></div>
        <el-input class="search_inp" v-model="search_val" placeholder="请输入所要查询的商品关键字"></el-input>
      </div>
      <div class="dia_head">
        <div class="hd_box" v-for="(item, index) in select_list" :key="index">
          <div class="tu_box">
            <img class="box_tu" :src="item.img_src" />
            <div class="box_iconbox">
              <span
                class="box_icon iconfont icon-shanchu"
                @click="fn2(item.id)"
              ></span>
            </div>
          </div>
          <div class="box_txt">ID:{{ item.id }}</div>
        </div>
      </div>
      <div class="br_box"></div>
      <div class="dia_main">
        <div class="main_box" v-for="(item, index) in goods_list" :key="index">
          <el-checkbox class="checkbox" v-model="item.v1" @change="fn1">
            <img class="goods_tu" :src="item.img_src" />
            <div class="rt">
              <div class="rt_top">{{ item.title }}</div>
              <div class="rt_center">ID:{{ item.id }}</div>
            </div>
          </el-checkbox>
        </div>
      </div>
      <div class="footer_btn">
        <el-button class="bt_cancel" @click="cancel_btn">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "../../common/axios";
import CusInput from "../from/cus-input.vue";
export default {
  components: {
    CusInput,
  },
  props:
  {
    id:{
      type:[String,Number],
      default:0
    }
  },
  data() {
    return {
      add_goods_tan: false, //控制添加商品弹框
      //需要传递给服务端的值
      activity_name: "", //活动名称
      act_start_end_time: [], //活动起止时间
      select_type: 1, // 选中活动类型的radio索引
      select_range:0, // 选中满减活动使用范围的radio索引
      total_price: "", //单笔订单金额大于等于的价格
      leg_price: "", //立减的价格
      man_Lijian: "", //单笔订单需要满多少件
      leg_piece: "", //单笔订单满足要求后打多少折
      full_piece: "", //单笔订单满多少件后就包邮
      full_price: "", // 单笔订单满多少钱后就包邮
      goods_list: [],
      select_free_shipping:0,
      search_val:"",
      select_list:[],
      act_goods_id:0,
      is_edit:false,
    };
  },
  mounted(){
    let id = this.id;
    this.act_goods_id = id;
    this.get_goods(id);
    if(id>0){
      this.get_market(id);
    }else{
      this.is_edit = true;
    }
  },
  methods: {
    get_market(id){
      let activity_name,start_time,end_time,select_type,select_range,total_price,leg_price,man_Lijian,leg_piece,full_piece,full_price,select_free_shipping;
      let act_start_end_time = [];
      this.is_edit = false;
      let url = "/adminshop/coupons/get_reduction.html";
      axios.post(url,{id}).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          activity_name = data.activity_name;
          start_time = data.start_time;
          end_time = data.end_time;
          select_type = data.select_type;
          select_range = data.select_range;
          total_price = data.total_price;
          leg_price = data.leg_price;
          man_Lijian = data.man_Lijian;
          leg_piece = data.leg_piece;
          full_piece = data.full_piece;
          full_price = data.full_price;
          select_free_shipping = data.select_free_shipping;
          
        } else {
          console.log(res.error);
        }
        if(start_time!='' && start_time!=null && start_time!=undefined && end_time!='' && end_time!=null && end_time!=undefined){
          act_start_end_time = [new Date(start_time),new Date(end_time)]
        }
        this.activity_name = activity_name;
        this.act_start_end_time = act_start_end_time;
        this.start_time = start_time;
        this.end_time = end_time;
        this.select_type = select_type;
        this.select_range = select_range;
        this.total_price = total_price;
        this.leg_price = leg_price;
        this.man_Lijian = man_Lijian;
        this.leg_piece = leg_piece;
        this.full_piece = full_piece;
        this.full_price = full_price;
        this.select_free_shipping = select_free_shipping;
        this.is_edit = true;
      });
    },
    get_goods(id=0){
      let shop_list = [];
      let url = "/adminshop/coupons/get_goods.html";
      let data = {id,type:0};
      axios.post(url,data).then((res) => {
          let success = res.success;
          let data = res.data;
          if (success) {
              let list = data.list;
              if (list != undefined && list != null && list != "") {
                shop_list = list;
              }
          } else {
              console.log(res.error);
          }
          this.goods_list = shop_list;
      });
    },
    chenginput(e) {
      let value = e.value;
      this.activity_name = value;
    },
    show_add_tan() {
      this.add_goods_tan = true;
    },
    cancel_btn(){
      this.add_goods_tan = false;
    },
    Previous() {
      if(this.id == 0){
        this.$emit("okm",0);
      }else{
        this.$emit("okm",2);
      }
      
    },
    datetime(e) { //时间选择器
      if (e != null && e != undefined && e != "") {
        this.start_time = this.dataSearch(e[0]);
        this.end_time = this.dataSearch(e[1]);
      }
    },
    dataSearch(data) {
      if (data == null || data == undefined || data == "") {
        return "";
      }
      var yearn = data.getFullYear();
      var monthn = data.getMonth() + 1;
      var dayn = data.getDate();
      var h = data.getHours();
      var m = data.getMinutes();
      var s = data.getSeconds();
      if (monthn < 10) {
        monthn = "0" + monthn;
      }
      if (dayn < 10) {
        dayn = "0" + dayn;
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }
      if (s < 10) {
        s = "0" + s;
      }
      return yearn + "-" + monthn + "-" + dayn + " " + h + ":" + m + ":" +s;
    },
    fn1() {
      // console.log('e',e)
      this.select_list = this.goods_list.filter((item) => item.v1 === true);
      // console.log(this.select_list)
    },
    fn2(id) {
      // console.log('id',id)
      this.select_list.forEach((item, index) => {
        if (item.id === id) {
          this.select_list.splice(index, 1);
          this.goods_list.forEach((aitem, aindex) => {
            if (aitem.id === id) {
              this.goods_list[aindex].v1 = false;
            }
          });
        }
      });
    },
    show_act_list() {
      let { 
        activity_name, //活动名称
        start_time, //活动起止时间
        end_time,
        select_type, // 选中活动类型的radio索引
        select_range, // 选中满减活动使用范围的radio索引
        select_list,
        select_free_shipping,
        total_price, //单笔订单金额大于等于的价格
        leg_price, //立减的价格
        man_Lijian, //单笔订单需要满多少件
        leg_piece, //单笔订单满足要求后打多少折
        full_piece, //单笔订单满多少件后就包邮
        full_price, // 单笔订单满多少钱后就包邮
      } = this;

      let url =  "/adminshop/coupons/add_reduction.html";
      let id = this.act_goods_id;
      if(id>0){
        url =  "/adminshop/coupons/edit_reduction.html";
      }
      let data = {
        id,
        activity_name, //活动名称
        start_time, //活动起止时间
        end_time,
        select_type, // 选中活动类型的radio索引
        select_range, // 选中满减活动使用范围的radio索引
        select_list,
        select_free_shipping,
        total_price, //单笔订单金额大于等于的价格
        leg_price, //立减的价格
        man_Lijian, //单笔订单需要满多少件
        leg_piece, //单笔订单满足要求后打多少折
        full_piece, //单笔订单满多少件后就包邮
        full_price
      }

      axios.post(url,data).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          if (data.status == 1) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            setTimeout(()=>{
              //跳转到活动列表页
              this.$emit("okm",2);
            },1000)
          } else {
            // console.log(data);
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        } else {
          console.log(res.error);
        }
      });
    },
  },
};
</script>

<style scoped>
@import "./Market_activity.css";
.search_inp /deep/ .el-input__inner{
  border: 0;
  padding-left: 0;
}
.checkbox /deep/ .el-checkbox__label {
  display: flex;
}

.m2_box1 /deep/ .el-radio-group{
  display: flex;
  flex-direction: column;
}
.m2_box1 /deep/ .el-radio{
  padding: 7px 0;
  display: flex;
  align-items: center;
}
</style>
