<template>
	<div class="Market_activity_zong">
		<template v-if="Jump_page == 0">
			<div class="head">店铺活动</div>
			<div class="activity_list">
				<div class="act_item">
					<div class="item_hd">
						<div class="hd_left">满减活动</div>
						<div class="hd_right">
							<el-button class="create_bt" @click="bn1(1)">创建</el-button>
							<div class="hd_txt" @click="bn1(2)">我的活动列表</div>
						</div>
					</div>
					<div class="item_bottom">
						<div class="bt_top">轻松提高客单,日常活动期间出货利器。</div>
						<img class="bt_tu" src="../../static/img/wxl_mark_act_tu1.png" />
					</div>
				</div>
				<div class="act_item">
					<div class="item_hd">
						<div class="hd_left">店铺优惠券</div>
						<div class="hd_right">
							<el-button class="create_bt" @click="bn1(3)">创建</el-button>
							<div class="hd_txt" @click="bn1(4)">我的活动列表</div>
						</div>
					</div>
					<div class="item_bottom">
						<div class="bt_top">轻松提高客单,日常活动期间出货利器。</div>
						<img class="bt_tu" src="../../static/img/wxl_mark_act_tu2.png" />
					</div>
				</div>
			</div>
		</template>
		<!-- 创建满减活动页面 -->
		<template v-if="Jump_page == 1">
			<Activity @okm="bn1" :id="act_goods_id" />
		</template>
		<!-- 我的满减活动列表页 -->
		<template v-if="Jump_page == 2">
			<Myactivitylist @ikz="bn2" @ikx="bn1" />
		</template>
		<!-- 创建店铺优惠券页面 -->
		<template v-if="Jump_page == 3">
			<Createcoupons @pkx="bn1" :cid="act_goods_id" />
		</template>
		<!-- 我的优惠券活动列表页 -->
		<template v-if="Jump_page == 4">
			<Mycouponlist @ukx="bn2" @ikx="bn1" />
		</template>
	</div>
</template>

<script>
	import Activity from "./activity.vue";
	import Myactivitylist from "./my_activity_list.vue";
	import Createcoupons from "./create_coupons.vue";
	import Mycouponlist from "./my_coupon_list.vue"
	export default {
		props: ["Jump_page1"],
		components: {
			Activity,
			Myactivitylist,
			Createcoupons,
			Mycouponlist,
		},
		data() {
			return {
				Jump_page: 0, //控制跳转的页面
				act_goods_id: '', //满减活动编辑商品传过来的id
			};
		},
		methods: {
			bn1(e) {
				this.act_goods_id = "";
				this.Jump_page = e;
			},
			bn2(e) {
				this.act_goods_id = "";
				this.Jump_page = e[0];
				this.act_goods_id = e[1]
			},
		},
	};
</script>

<style scoped>
	@import "./Market_activity.css";
</style>
