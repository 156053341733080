<template>
	<div>
		<div class="head_main">
			<div class="act_list_head">
				满减优惠
				<div class="act_icon iconfont icon-zuo" @click="go_back"></div>
			</div>
			<!-- 中间搜索框 -->
			<div class="hd_search">
				<div class="search_box">
					<div class="txt">活动名称</div>
					<input class="inp" v-model="title" />
				</div>

				<div class="search_box">
					<div class="txt">获得方式</div>
					<el-select class="select" v-model="value3" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search_box">
					<div class="txt">状态</div>
					<el-select class="select" v-model="value2" placeholder="请选择">
						<el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search_box">
					<div class="txt"></div>
					<el-button class="search_btn" @click="sub_search">搜索</el-button>
				</div>
				<div class="clear"></div>
			</div>
			<!-- 批量操作商品 -->
			<div class="goods_edit">
				<el-button class="edit_box" :disabled="Confirm_shipment"
					:class="Confirm_shipment === false ? 'edit_box1' : 'edit_box'" @click="show_del_tan">
					删除
				</el-button>
				<el-dialog class="bba1" center custom-class="dialog1" title="确定" :visible.sync="del_tan">
					<div class="dia_txt1">
						删除<span style="color: red">满减优惠活动</span>
					</div>
					<div class="footer_btn">
						<el-button class="bt_cancel" @click="cancel_btn2">取消</el-button>
						<el-button class="bt_confirm">确定</el-button>
						<!-- 事件名已经在下面给定了-->
					</div>
				</el-dialog>
				<div class="edit_hou">已选商品({{ Select_goods_num }})</div>
			</div>
		</div>
		<div class="bbb">
			<table class="table">
				<!-- 表头 -->
				<thead class="list_hd">
					<th class="it">活动名称</th>
					<th class="it">获得方式</th>
					<th class="it">商品信息</th>
					<th class="it">活动起止时间</th>
					<th class="it">状态</th>
					<th class="it">操作</th>
				</thead>
				<!-- 表单内容 -->
				<tbody class="list_text" v-for="(item, index) in act_list" :key="index">
					<tr>
						<td class="gds_item">
							<el-checkbox class="checkbox" v-model="item.v1" @change="hra(item.v1)">
								<div class="check_txt">{{ item.title }}</div>
							</el-checkbox>
						</td>
						<!-- 活动类型 -->
						<td>
							<div class="act_type">
								<span v-if="item.receive == 1">领取</span>
								<span v-else>立减</span>
							</div>
						</td>
						<!-- 商品信息 -->
						<td>
							<template v-if="item.is_all == 1">
								<div class="goods_message">全部商品</div>
							</template>
							<template v-else>
								<div class="goods_message">部分商品</div>
								<div class="look" @click="show_add_tan(2)">
									<u>查看</u>
								</div>
							</template>
						</td>
						<!-- 活动起止时间 -->
						<td style="width: 200px">
							<div class="start_time">{{ item.start_time }}</div>
							<div class="end_time">{{ item.end_time }}</div>
						</td>
						<!-- 状态 -->
						<td>
							<div class="act_status">
								<span v-if="item.status == 1">生效中</span>
								<span v-else style="color: red">已失效</span>
							</div>
						</td>
						<!-- 操作 -->
						<td>
							<div class="add_good" @click="show_create_act(item.id)"><u>编辑</u></div>
							<div class="add_good" @click="show_del_tan(item.id)"><u>删除</u></div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 查看参加活动的商品对话框 -->
		<el-dialog class="bba" center :show-close="false" custom-class="dialog" :visible.sync="add_goods_tan">
			<!-- <div class="dia_search">
        <div class="sea_icon iconfont icon-chaxun"></div>
        <el-input
          class="search_inp"
          v-model="search_val"
          placeholder="请输入所要查询的商品关键字"
        ></el-input>
      </div> -->
			<div class="dia_head">
				<div class="hd_box" v-for="(item, index) in select_list" :key="index">
					<div class="tu_box">
						<img class="box_tu" :src="item.img_src" />
						<div class="box_iconbox">
							<span class="box_icon iconfont icon-shanchu" @click="fn2(item.id)"></span>
						</div>
					</div>
					<div class="box_txt">ID:{{ item.id }}</div>
				</div>
			</div>
			<div class="br_box"></div>
			<div class="dia_main">
				<div class="main_box" v-for="(item, index) in goods_list" :key="index">
					<!-- <el-checkbox class="checkbox" v-model="item.v1" @change="fn1">
            
          </el-checkbox> -->
					<div class="checkbox">
						<img class="goods_tu" :src="item.img_src" />
						<div class="rt">
							<div class="rt_top">{{ item.title }}</div>
							<div class="rt_center">ID:{{ item.id }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer_btn">
				<el-button class="bt_cancel" @click="cancel_btn">关闭</el-button>
				<!-- <el-button class="bt_cancel" @click="confirm_btn">移除</el-button> -->
			</div>
		</el-dialog>

		<page-tab :page="page" :count="page_count" @change="change_page"></page-tab>
	</div>
</template>

<script>
	import axios from "../../common/axios";
	import pageTab from "../pages/index";
	export default {
		components: {
			pageTab,
		},
		data() {
			return {
				opb: 0, //显示dialog弹框传的参数
				value3: 0, // 订单状态选中的值
				value2: 0,
				title: "",
				Confirm_shipment: true, // 控制确认发货按钮
				del_tan: false, // 控制删除商品弹框
				Select_goods_num: 0, //选中商品的数量
				add_goods_tan: false,
				search_val: "",
				options: [{
						value: 0,
						label: "全部",
					},
					{
						value: 1,
						label: "立减",
					},
					{
						value: 2,
						label: "领取",
					},
				],
				options1: [{
						value: 0,
						label: "全部",
					},
					{
						value: 1,
						label: "生效中",
					},
					{
						value: 2,
						label: "已失效",
					},
				],
				act_list: [],
				page: 1,
				page_count: 1,
				select_list: [],
				goods_list: [],
			};
		},
		mounted() {
			this.get_coupons();
		},
		methods: {
			show_del_tan() {
				//控制删除商品弹框
				this.del_tan = !this.del_tan;
			},
			cancel_btn2() {
				// 删除商品弹框取消按钮事件
				this.del_tan = !this.del_tan;
			},
			hra(e) {
				//选中其中一个退款订单，触发相对应订单能操作的按钮
				let aa = this.act_list.filter((item) => item.v1 === true);
				if (aa.length > 0) {
					this.Confirm_shipment = false;
				} else {
					this.Confirm_shipment = true;
				}

				// 选中商品的数量
				if (e === true) {
					this.Select_goods_num += 1;
				} else {
					this.Select_goods_num -= 1;
				}
			},
			show_add_tan() {
				
				let title = this.title;
				let shop_list = [];
				this.select_list = [];
				let url = "/adminshop/coupons/coupons_goods.html";
				axios.post(url,{title}).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						let list = data.list;
						if (list != undefined && list != null && list != "") {
							for (var i in list) {
								shop_list[i] = list[i];
								shop_list[i].v1 = false;
							}
						}
					} else {
						console.log(res.error);
					}
					this.goods_list = shop_list;
					this.add_goods_tan = true;
				});
			},
			show_create_act(e) {
				//切换到创建优惠券活动页
				this.$emit("ukx", [3, e]);
			},
			go_back() {
				this.$emit("ikx", 0);
			},
			cancel_btn() {
				this.add_goods_tan = false;
			},
			sub_search() {
				this.page = 1;
				this.get_coupons();
			},
			change_page(e) {
				let page = e.value;
				this.page = page;
				this.get_coupons(page);
			},
			get_coupons(page = 1) {
				if (page <= 1) {
					page = 1;
				}
				
				let title = this.title;
				let type = this.value3;
				let status = this.value2;
				let shop_list = [];
				let url = "/adminshop/coupons/coupons.html";
				let data = {
					page,
					title,
					type,
					status
				}
				axios.post(url,data).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						let list = data.list;
						if (list != undefined && list != null && list != "") {
							for (var i in list) {
								shop_list[i] = list[i];
								shop_list[i].v1 = false;
							}
						}
						this.page_count = parseInt(data.pages);
					} else {
						console.log(res.error);
					}
					this.act_list = shop_list;
				});
			},
			fn1() {
				this.select_list = this.goods_list.filter((item) => item.v1 === true);
			},
			fn2(id) {
				// console.log('id',id)
				this.select_list.forEach((item, index) => {
					if (item.id === id) {
						this.select_list.splice(index, 1);
						this.goods_list.forEach((aitem, aindex) => {
							if (aitem.id === id) {
								this.goods_list[aindex].v1 = false;
							}
						});
					}
				});
			},
			del_btn() {
				// console.log(this.select_list)

			}
		},
	};
</script>

<style scoped>
	@import "./Market_activity.css";

	.bba1 /deep/ .el-dialog__title {
		font-size: 20px;
		font-weight: bold;
		line-height: 0;
	}

	.bba /deep/ .el-dialog__header {
		padding: 0 0;
	}

	.bba /deep/ .el-dialog__title {
		font-weight: bold;
		line-height: 0;
	}

	.bba /deep/ .el-dialog__body {
		padding: 12px 12px;
	}

	.bba /deep/ .el-dialog {
		border-radius: 10px;
	}

	.checkbox /deep/ .el-checkbox__label {
		display: flex;
	}

	.dia_main {
		height: 400px !important;
	}
</style>
