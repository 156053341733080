<template>
	<div>
		<div class="create_head1">活动信息</div>
		<div class="create_mainf">
			<div class="create_mainf1">
				<div class="m1_hd">编辑活动信息</div>
				<!-- <div class="m1_box">
					<div class="box_txt">发放渠道：</div>
					<el-radio-group v-model="Distribution_channel" class="radio_group" @change="get_channel">
						<el-radio :label="1">店铺常规展示</el-radio>
						<el-radio :label="2">官方推广渠道</el-radio>
					</el-radio-group>
				</div> -->
				<div class="m1_box">
					<div class="box_txt">活动名称：</div>
					<CusInput class="box_inp" :value="activity_name" v-if="is_edit" :max_num="20"
						@chenginput="chenginput" />
				</div>
				<div class="m1_box">
					<div class="box_txt">活动起止时间：</div>
					<el-date-picker v-model="act_start_end_time" @change="datetime" type="datetimerange"
						range-separator="——" start-placeholder="开始日期" end-placeholder="结束日期" class="date_picker">
					</el-date-picker>
				</div>
			</div>
			<div class="create_mainf2">
				<div class="m2_hd">设置活动类型和活动详情</div>
				<div class="m2_box">
					<div class="box_txt">优惠券商品使用范围：</div>
					<el-radio-group v-model="select_range" class="radio_group">
						<el-radio :label="0">全店所有商品</el-radio>
						<el-radio :label="1">部分商品</el-radio>
					</el-radio-group>
				</div>
				<template v-if="select_range === 1">
					<div class="m2_box">
						<div class="box_txt"></div>
						<el-button class="add_goods" @click="show_add_tan(1)">+添加商品<span
								v-if="select_list.length>0">(已选{{select_list.length}})</span></el-button>
					</div>
				</template>
				<div class="m2_box">
					<div class="box_txt">获取方式：</div>
					<el-radio-group v-model="select_channel" class="radio_group">
						<el-radio :label="0">立减</el-radio>
						<el-radio :label="1">领取</el-radio>
					</el-radio-group>
				</div>
				<div class="m2_box">
					<div class="box_txt">面额：</div>
					<el-input class="m2_inp" v-model="price"></el-input>
					<span style="font-size: 17px; margin-left: 10px">元</span>
					<span style="font-size: 17px; margin-left: 10px">(0或者空代表无限制)</span>
				</div>
				<template v-if="select_channel === 0">
					<div class="m2_box">
						<div class="box_txt">立减：</div>
						<el-input class="m2_inp" v-model="subtract"></el-input>
						<span style="font-size: 17px; margin-left: 10px">元</span>
					</div>
				</template>
				<div class="m2_box">
					<div class="box_txt">发放总数：</div>
					<el-input class="m2_inp" v-model="total"></el-input>
					<span style="font-size: 17px; margin-left: 10px">份</span>
				</div>
				<div class="m2_box">
					<div class="box_txt">每人限额：</div>
					<el-input class="m2_inp" v-model="share"></el-input>
					<span style="font-size: 17px; margin-left: 10px">份</span>
					<span style="font-size: 17px; margin-left: 10px">(0或者空代表无限制)</span>
				</div>
				<div class="m2_box" v-if="select_channel == 1">
					<div class="box_txt">使用时间：</div>
					<el-radio-group v-model="coup_use_time" class="radio_group">
						<el-radio :label="1">
							买家领取成功起有效天数
							<el-input class="m2_inp1" v-model="effective_days"></el-input>
							天
						</el-radio>
						<el-radio :label="0">活动有效期</el-radio>
					</el-radio-group>
				</div>
			</div>
		</div>
		<div class="create_footer1">
			<el-button class="foot_bt1" @click="Previous">返回上一步</el-button>
			<el-button class="foot_bt2" @click="show_act_list">提交</el-button>
		</div>
		<!-- 添加商品参加活动对话框 -->
		<el-dialog class="bba" center :show-close="false" custom-class="dialog" :visible.sync="add_goods_tan">
			<div class="dia_search">
				<div class="sea_icon iconfont icon-chaxun"></div>
				<el-input class="search_inp" v-model="search_val" placeholder="请输入所要查询的商品关键字"></el-input>
			</div>
			<div class="dia_head">
				<div class="hd_box" v-for="(item, index) in select_list" :key="index">
					<div class="tu_box">
						<img class="box_tu" :src="item.img_src" />
						<div class="box_iconbox">
							<span class="box_icon iconfont icon-shanchu" @click="fn2(item.id)"></span>
						</div>
					</div>
					<div class="box_txt">ID:{{ item.id }}</div>
				</div>
			</div>
			<div class="br_box"></div>
			<div class="dia_main">
				<div class="main_box" v-for="(item, index) in goods_list" :key="index">
					<el-checkbox class="checkbox" v-model="item.v1" @change="fn1">
						<img class="goods_tu" :src="item.img_src" />
						<div class="rt">
							<div class="rt_top">{{ item.title }}</div>
							<div class="rt_center">ID:{{ item.id }}</div>
						</div>
					</el-checkbox>
				</div>
			</div>
			<div class="footer_btn">
				<el-button class="bt_cancel" @click="cancel_btn">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import CusInput from "../from/cus-input.vue";
	import axios from "../../common/axios";
	export default {
		components: {
			CusInput,
		},
		props: {
			cid: {
				type: [String, Number],
				default: 0,
			}
		},
		data() {
			return {
				opb: 0, //显示dialog弹框传的参数
				Distribution_channel: 1, //发放渠道-初始的radio索引
				coup_use_time: 1, //优惠券有效使用时间-初始的radio索引
				//需要传递给服务端的值
				activity_name: "", //活动名称
				act_start_end_time: [], //活动起止时间
				select_channel: 0, //选中发放渠道的radio索引
				select_range: 0, //选中店铺优惠券活动使用范围的radio索引
				price: "", //面额
				subtract: "", //立减
				total: "", //发放总数
				share: "", //每人限额
				start_time: '',
				end_time: '',
				effective_days: '', //优惠券有效天数
				add_goods_tan: false,
				goods_list: [],
				search_val: "",
				select_list: [],
				act_goods_id: 0,
				is_edit: false,
			};
		},
		mounted() {
			let id = this.cid;
			this.act_goods_id = id;
			this.get_goods(id);
			if (id > 0) {
				this.get_market(id);
			} else {
				this.is_edit = true;
			}

		},
		methods: {
			get_market(id) {
				let activity_name, start_time, end_time, select_range, select_channel, price, subtract, total, share,
					coup_use_time, effective_days;
				let act_start_end_time = [];

				this.is_edit = false;
				let url = "/adminshop/coupons/get_coupons.html";
				axios.post(url,{id}).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						activity_name = data.activity_name;
						start_time = data.start_time;
						end_time = data.end_time;
						select_range = data.select_range;
						select_channel = data.select_channel;
						price = data.price;
						subtract = data.subtract;
						total = data.total;
						share = data.share;
						coup_use_time = data.coup_use_time;
						effective_days = data.effective_days;

					} else {
						console.log(res.error);
					}
					if (start_time != '' && start_time != null && start_time != undefined && end_time != '' &&
						end_time != null && end_time != undefined) {
						act_start_end_time = [new Date(start_time), new Date(end_time)]
					}
					this.activity_name = activity_name;
					this.act_start_end_time = act_start_end_time;
					this.start_time = start_time;
					this.end_time = end_time;
					this.select_range = select_range;
					this.select_channel = select_channel;
					this.price = price;
					this.subtract = subtract;
					this.total = total;
					this.share = share;
					this.coup_use_time = coup_use_time;
					this.effective_days = effective_days;
					this.is_edit = true;
				});
			},
			get_goods(id = 0) {

				let shop_list = [];
				let url = "/adminshop/coupons/get_goods.html";
				let data = {id,type: 1}
				axios.post(url,data).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						let list = data.list;
						if (list != undefined && list != null && list != "") {
							shop_list = list;
						}
					} else {
						console.log(res.error);
					}
					this.goods_list = shop_list;
					this.fn1();
				});
			},
			chenginput(e) {
				let value = e.value;
				this.activity_name = value;
			},
			datetime(e) { //时间选择器
				// console.log(e);
				if (e != null && e != undefined && e != "") {
					// console.log(e);
					this.start_time = this.dataSearch(e[0]);
					this.end_time = this.dataSearch(e[1]);
					// console.log(this.start_time,this.end_time)
				}
			},

			dataSearch(data) {
				if (data == null || data == undefined || data == "") {
					return "";
				}
				var yearn = data.getFullYear();
				var monthn = data.getMonth() + 1;
				var dayn = data.getDate();
				var h = data.getHours();
				var m = data.getMinutes();
				var s = data.getSeconds();
				if (monthn < 10) {
					monthn = "0" + monthn;
				}
				if (dayn < 10) {
					dayn = "0" + dayn;
				}
				if (h < 10) {
					h = "0" + h;
				}
				if (m < 10) {
					m = "0" + m;
				}
				if (s < 10) {
					s = "0" + s;
				}
				return yearn + "-" + monthn + "-" + dayn + " " + h + ":" + m + ":" + s;
			},

			fn1() {
				// console.log('e',e)
				this.select_list = this.goods_list.filter((item) => item.v1 === true);
				// console.log(this.select_list)
			},
			fn2(id) {
				// console.log('id',id)
				this.select_list.forEach((item, index) => {
					if (item.id === id) {
						this.select_list.splice(index, 1);
						this.goods_list.forEach((aitem, aindex) => {
							if (aitem.id === id) {
								this.goods_list[aindex].v1 = false;
							}
						});
					}
				});
			},

			show_add_tan() {
				this.add_goods_tan = true;
			},
			cancel_btn() {
				this.add_goods_tan = false;
			},
			Previous() {
				if (this.cid == 0) {
					this.$emit("pkx", 0);
				} else {
					this.$emit("pkx", 4);
				}
			},
			show_act_list() {
				let {
					activity_name,
					start_time,
					end_time,
					select_range,
					select_list,
					select_channel,
					price,
					subtract,
					total,
					share,
					coup_use_time,
					effective_days
				} = this;

				
				let url = "/adminshop/coupons/add_coupons.html";
				let id = this.act_goods_id;
				if (id > 0) {
					url = "/adminshop/coupons/edit_coupons.html";
				}
				axios.post(url,{
					id,
					activity_name,
					start_time,
					end_time,
					select_range,
					select_list,
					select_channel,
					price,
					subtract,
					total,
					share,
					coup_use_time,
					effective_days	
				}).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						if (data.status == 1) {
							this.$message({
								type: "success",
								message: data.msg,
							});
							setTimeout(() => {
								//跳转到活动列表页
								this.$emit("pkx", 4);
							}, 1000)
						} else {
							// console.log(data);
							this.$message({
								type: "error",
								message: data.msg,
							});
						}
					} else {
						console.log(res.error);
					}
				});
			},
		},
	};
</script>

<style scoped>
	@import "./Market_activity.css";

	.search_inp /deep/ .el-input__inner {
		border: 0;
		padding-left: 0;
	}

	.checkbox /deep/ .el-checkbox__label {
		display: flex;
	}
</style>
